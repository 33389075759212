<template>
  <v-card>
    <v-card-title class="primary white--text">Crear nuevo material</v-card-title>
    <v-card-text>
      <form-section title="Datos del material"/>
      <v-form ref="form" v-model="validation.valid" lazy-validation>
        <div class="d-flex flex-column flex-md-row">
          <v-text-field v-model="value.name"
                        :rules="validation.rules.name"
                        label="Nombre"
                        placeholder="Ingrese el nombre del material"
                        outlined/>
          <v-text-field v-model="value.description"
                        class="ml-md-2"
                        label="Descripción"
                        placeholder="Ingrese la descripción"
                        outlined/>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn depressed outlined @click="handleCancelForm">
        Cancelar
      </v-btn>
      <v-spacer/>
      <v-btn class="primary" @click="handleSubmitForm">Guardar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FormSection from "@/components/widgets/Section"

export default {
  name: "ToolForm",
  components: { FormSection },
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    validation: {
      valid: false,
      rules: {
        name: [v => !!v || "Ingrese el nombre"],
      },
    },
  }),
  methods: {
    handleSubmitForm() {
      if (!this.$refs.form.validate()) return;
      this.$emit('input', this.value)
    },
    handleCancelForm() {
      this.$refs.form.resetValidation()
      this.$emit('cancel')
    },
  }
}
</script>

<style scoped>

</style>