<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="3">
          <v-toolbar color="primary" flat>
            <v-text-field
              v-model="filter.query"
              :prepend-inner-icon="'mdi-filter-variant'"
              append-icon="mdi-magnify"
              clearable
              hide-details
              placeholder="Aa..."
              solo
              text
              @keyup.enter="fetchRecords(filter)"
              @click:clear="fetchRecords(filter)"
            />
            <v-btn icon @click="fetchRecords(filter)">
              <v-icon color="white">mdi-refresh</v-icon>
            </v-btn>
            <v-dialog v-model="dialog" max-width="700">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" icon v-on="on">
                  <v-icon color="white">mdi-plus</v-icon>
                </v-btn>
              </template>
              <tool-form v-model="editedItem" @cancel="dialog = false" @input="onSubmit"/>
            </v-dialog>
          </v-toolbar>
          <v-card-text>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="subtitle-1">
                  ¿Estás seguro de que quieres eliminar el material?
                </v-card-title>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn color="blue darken-1" text>
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="confirmDelete">
                    OK
                  </v-btn>
                  <v-spacer/>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-data-table
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-options': [5, 10, 15, 50],
              }"
              :headers="headers"
              :items="Object.keys(dictionary.tools).map(v => dictionary.tools[v])"
              :loading="loadingItems"
              :options.sync="options"
              :server-items-length="serverItemsLength"
              disable-sort
            >
              <template v-slot:[`item.name`]="props">
                <v-edit-dialog
                  :return-value.sync="props.item.name"
                  cancel-text="Cancelar"
                  large
                  save-text="Guardar"
                  @save="save(props.item)"
                >
                  {{ props.item.name }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.name"
                      counter
                      placeholder="Nombre"
                      single-line/>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:[`item.description`]="props">
                <v-edit-dialog
                  :return-value.sync="props.item.description"
                  cancel-text="Cancelar"
                  large
                  save-text="Guardar"
                  @save="save(props.item)"
                >
                  {{ props.item.description }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.description"
                      counter
                      placeholder="Descripción"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                           class="ma-1"
                           color="red"
                           depressed
                           fab
                           x-small
                           v-on="on"
                           @click="showDeleteDialog(item)">
                      <v-icon color="white" small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ToolForm from "@/components/forms/ToolForm";
import EventBus from "@/util/eventbus";

export default {
  name: "ToolList",
  components: { ToolForm },
  data: () => ({
    loadingItems: false,
    serverItemsLength: 0,
    options: {},
    editedItem: {},
    defaultItem: {},
    dialogDelete: false,
    dialog: false,
    filter: {
      page: 1,
      query: null,
    },
    headers: [
      { text: "Código", value: "code", width: 100, },
      { text: "Nombre", value: "name", width: 400, },
      { text: "Descripción", value: "description", },
      { text: "Acciones", value: "actions", width: 100, },
    ],
  }),
  computed: {
    ...mapState("library", ["dictionary"]),
  },
  watch: {
    options: {
      handler() {
        const { page, itemsPerPage } = this.options;
        this.filter.PageSize = itemsPerPage;
        this.filter.page = page;
        this.fetchRecords(this.filter);
      },
      deep: true,
    },
    dialog(val) {
      val || this.closeEditDialog();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    ...mapActions("library", ["fetchTools", "createTool", "deleteTool", 'editTool']),
    fetchRecords(query) {
      this.loadingItems = true;
      this.fetchTools(query)
        .then(total => this.serverItemsLength = total)
        .finally(() => (this.loadingItems = false));
    },
    showDeleteDialog(item) {
      this.dialogDelete = true
      this.editedItem = item
    },
    closeEditDialog() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    onSubmit(item) {
      this.createTool(item)
        .then(() => {
          this.dialog = false
          this.serverItemsLength++
          EventBus.$emit('SHOW_SNACKBAR', {
            text: 'Los cambios para el cliente se han guardado',
            color: 'success'
          })
        })
    },
    save(item) {
      this.editTool(item)
        .then(() => {
          console.log('Tool edited')
        })
    },
    confirmDelete() {
      this.deleteTool(this.editedItem.code)
        .then(() => {
          this.dialogDelete = false
          this.serverItemsLength--
          EventBus.$emit('SHOW_SNACKBAR', {
            text: 'Se ha eliminado el material',
            color: 'success'
          })
        })
    }
  },
}
</script>

<style scoped>

</style>