var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"3"}},[_c('v-toolbar',{attrs:{"color":"primary","flat":""}},[_c('v-text-field',{attrs:{"prepend-inner-icon":'mdi-filter-variant',"append-icon":"mdi-magnify","clearable":"","hide-details":"","placeholder":"Aa...","solo":"","text":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchRecords(_vm.filter)},"click:clear":function($event){return _vm.fetchRecords(_vm.filter)}},model:{value:(_vm.filter.query),callback:function ($$v) {_vm.$set(_vm.filter, "query", $$v)},expression:"filter.query"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.fetchRecords(_vm.filter)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-refresh")])],1),_c('v-dialog',{attrs:{"max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('tool-form',{on:{"cancel":function($event){_vm.dialog = false},"input":_vm.onSubmit},model:{value:(_vm.editedItem),callback:function ($$v) {_vm.editedItem=$$v},expression:"editedItem"}})],1)],1),_c('v-card-text',[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"subtitle-1"},[_vm._v(" ¿Estás seguro de que quieres eliminar el material? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.confirmDelete}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1),_c('v-data-table',{attrs:{"footer-props":{
              showFirstLastPage: true,
              'items-per-page-options': [5, 10, 15, 50],
            },"headers":_vm.headers,"items":Object.keys(_vm.dictionary.tools).map(function (v) { return _vm.dictionary.tools[v]; }),"loading":_vm.loadingItems,"options":_vm.options,"server-items-length":_vm.serverItemsLength,"disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.name,"cancel-text":"Cancelar","large":"","save-text":"Guardar"},on:{"update:returnValue":function($event){return _vm.$set(props.item, "name", $event)},"update:return-value":function($event){return _vm.$set(props.item, "name", $event)},"save":function($event){return _vm.save(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"counter":"","placeholder":"Nombre","single-line":""},model:{value:(props.item.name),callback:function ($$v) {_vm.$set(props.item, "name", $$v)},expression:"props.item.name"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.name)+" ")])]}},{key:"item.description",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.description,"cancel-text":"Cancelar","large":"","save-text":"Guardar"},on:{"update:returnValue":function($event){return _vm.$set(props.item, "description", $event)},"update:return-value":function($event){return _vm.$set(props.item, "description", $event)},"save":function($event){return _vm.save(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"counter":"","placeholder":"Descripción"},model:{value:(props.item.description),callback:function ($$v) {_vm.$set(props.item, "description", $$v)},expression:"props.item.description"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.description)+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"red","depressed":"","fab":"","x-small":""},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }